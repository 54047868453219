<template>
  <v-card>
    <v-card-text>
      <div class="mb-2">客戶使用紀錄</div>
      <div style="font-size: 16px;" class="mb-2">
        <div class="font-weight-black grey--text text--darken-4 mb-1">
          店家基本資料
        </div>
        <div>
          <div class="mb-1">
            <span class="font-weight-black grey--text text--darken-4 mr-1"
              >營業登記:</span
            >
            <span>{{ currentProvider.value === "ALL" ? "" : currentProvider.text }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-black grey--text text--darken-4 mr-1"
              >統一編號:</span
            >
            <span>{{ currentProvider.uniform_number }}</span>
          </div>
          <div class="d-flex">
            <span class="font-weight-black grey--text text--darken-4 mr-1"
              >時間區間:</span
            >
            <span class="mr-2">
              <v-menu
                v-model="dateRange[0]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStart"
                    label="開始日"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  @input="dateRange[0] = false"
                ></v-date-picker>
              </v-menu>
            </span>
            <span class="mr-2">
              <v-menu
                v-model="dateRange[1]"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateEnd"
                    label="結束日"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  @input="dateRange[1] = false"
                ></v-date-picker>
              </v-menu>
            </span>
            <span class="mr-2">
              <v-select
                label="報表種類"
                :items="selectElementItemsForRecordType"
                v-model="selectElementSelectValueForRecordType"
                return-object
              ></v-select>
            </span>
            <span class="mr-2">
              <v-autocomplete
                label="請選擇營業登記"
                :items="providerList"
                :filter="providerFilter"
                v-model="selectElementSelectValueForTitle"
              />
            </span>
            <v-btn depressed color="primary" @click="getData()">查詢</v-btn>
          </div>
        </div>
      </div>
      <div class="py-4 position-relative">
        <v-sheet class="pa-10 chart-area" color="rgba(0, 0, 0, .12)">
          <div
            class="pa-5 grey--text chart-left-text"
            style="writing-mode: vertical-lr"
          >
            次
          </div>
          <canvas id="option-chart" ref="chart"></canvas>
        </v-sheet>
      </div>
      <div class="py-4 position-relative">
        <v-sheet class="pa-10 chart-area" color="rgba(0, 0, 0, .12)">
          <div
            class="pa-5 grey--text chart-left-text"
            style="writing-mode: vertical-lr"
          >
            秒
          </div>
          <canvas id="ordertaking-chart" ref="orderTakingChart"></canvas>
        </v-sheet>
      </div>
      <div class="py-4 position-relative">
        <v-sheet class="pa-10 chart-area" color="rgba(0, 0, 0, .12)">
          <div
            class="pa-5 grey--text chart-left-text"
            style="writing-mode: vertical-lr"
          >
            秒
          </div>
          <canvas id="applypay-chart" ref="applyPayChart"></canvas>
        </v-sheet>
      </div>
      <div class="py-4 position-relative">
        <v-sheet class="pa-10 chart-area" color="rgba(0, 0, 0, .12)">
          <div
            class="pa-5 grey--text chart-left-text"
            style="writing-mode: vertical-lr"
          >
            百分比
          </div>
          <canvas id="orderlog-chart" ref="orderLogChart"></canvas>
        </v-sheet>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
// import listMixin from '@/components/list/mixins/listMixin'
import listConfig from "./reportConfig";

import Moment from "moment";

import Chart from "chart.js";

export default {
  // mixins: [listMixin],
  data: () => ({
    listKey: "report",
    meta: {},
    chart: "",
    orderTakingChart: "",
    applyPayChart: "",
    orderLogChart: "",
    dateStart: Moment()
      .startOf("month")
      .format("YYYY-MM-DD"),
    dateEnd: Moment()
      .endOf("month")
      .format("YYYY-MM-DD"),
    dateRange: [false, false],
    selectElementSelectValueForTitle: "ALL",
    selectElementItemsForRecordType: [
      { text: "日報表", value: "DAY" },
      { text: "周報表", value: "WEEK" },
      { text: "月報表", value: "MONTH" },
    ],
    selectElementSelectValueForRecordType: { text: "日報表", value: "DAY" },
    data: [],
    applyPayData: [],
    orderLogData: [],
    orderTakingData: [],
    dataRecordType: [],
    providerList: [],
    currentProvider: {
      text: "全部",
      value: "ALL",
      uniform_number: ""
    }
  }),
  computed: {
    listData() {
      const data = this.data;
      const newData = {
        info: {
          order_id: "",
          title: "",
          vat: "",
          scheme: "",
        },
        data: {},
        labels: [],
        value: [],
      };
      if (data.length > 0) {
        const firstData = data[0];
        for (let i in newData.info) {
          newData.info[i] = firstData[i];
        }

        for (let i in data) {
          const curr = data[i];
          const date = Moment(curr.date).format("MM-DD");
          newData.labels.push(date);
          const times = curr.times; //+ Math.floor((Math.random()*100)+1
          newData.value.push(times);
          // newData.data[curr.date] = curr.times
        }
      }
      return newData;
    },
    optionListLabels() {
      return this.data.map((e)=>e.date);
    },
    loginListData() {
      return this.data.map((e)=>e.login);
    },
    tradeListData() {
      return this.data.map((e)=>e.trade);
    },
    readListData() {
      return this.data.map((e)=>e.read);
    },
    orderTakingListLabels() {
      return this.orderTakingData.map((e)=>e.date);
    },
    orderTakingListData() {
      return this.orderTakingData.map((e)=>e.avgSecond);
    },
    applyPayListLabels() {
      return this.applyPayData.map((e)=>e.date);
    },
    applyPayListData() {
      return this.applyPayData.map((e)=>e.avgSecond);
    },
    orderLogListLabels() {
      return this.orderLogData.map((e)=>e.date);
    },
    orderLogListData() {
      return this.orderLogData.map((e)=>e.percent);
    },
  },
  mounted() {
    this.getData(true);
    this.getProviderList();
  },
  methods: {
    providerFilter(item, queryText, itemText) {
      const text = item.text;
      const uniform_number = item.uniform_number;
      return text?.indexOf(queryText) > -1 || uniform_number?.indexOf(queryText) > -1;
    },
    async beforeIndex() {
      await Promise.all([]);
    },
    async getProviderList() {
      const res = await this.$api.collection.providerApi.index({
        page:1,
        per:999,
        "sort[created_at]":"desc"
      });
      this.providerList = [
        {
          text: "全部",
          value: "ALL",
          uniform_number: ""
        },
        ...res.data.map((provider) => {
          return {
            text: provider.business_name,
            value: provider.id,
            uniform_number: provider.uniform_number
          }
        })
      ];
      console.log(this.providerList);
    },
    getData(firstTime) {
      console.log("[Initial]",firstTime);
      this.currentProvider = this.selectElementSelectValueForTitle;
      this.$api.collection.reportApi
        .track({
          startDate: this.dateStart,
          endDate: this.dateEnd,
          providerId: this.selectElementSelectValueForTitle,
          type: this.selectElementSelectValueForRecordType.value,
        })
        .then((res) => {
          console.log(res)
          this.data = res.options;
          this.applyPayData = res.applyPay;
          this.orderLogData = res.orderLog;
          this.orderTakingData = res.orderTaking;
          this.dataRecordType = this.selectElementSelectValueForRecordType;
          if (firstTime) {
            this.createChart();
          } else {
            this.updateChart();
          }
        });
    },
    getListConfig() {
      return listConfig;
    },
    updateChart() {
      this.$nextTick(() => {
        this.chart.data.labels = this.optionListLabels;
        this.chart.data.datasets[0].data = this.loginListData;
        this.chart.data.datasets[1].data = this.tradeListData;
        this.chart.data.datasets[2].data = this.readListData;
        this.chart.update();
        
        this.orderTakingChart.data.labels = this.orderTakingListLabels;
        this.orderTakingChart.data.datasets[0].data = this.orderTakingListData;
        this.orderTakingChart.update();

        this.applyPayChart.data.labels = this.applyPayListLabels;
        this.applyPayChart.data.datasets[0].data = this.applyPayListData;
        this.applyPayChart.update();

        this.orderLogChart.data.labels = this.orderLogListLabels;
        this.orderLogChart.data.datasets[0].data = this.orderLogListData;
        this.orderLogChart.update();
      });
    },
    createChart() {
      this.$nextTick(() => {
        const ctx = document.getElementById("option-chart").getContext("2d");
        const vm = this;
        this.chart = new Chart(ctx, {
          type: "line",
          data: {
            labels: this.optionListLabels,
            datasets: [
            {
                label: "登入",
                data: this.loginListData,
                fill: false,
                borderColor: "#0ea5e9",
              },
              {
                label: "交易",
                data: this.tradeListData,
                fill: false,
                borderColor: "#10b981",
              },
              {
                label: "查看",
                data: this.readListData,
                fill: false,
                borderColor: "#db2777",
              },
            ],
          },
          plugins: [{}],
          options: {
            hover: { animationDuration: 0 },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            animation: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
              },
              scales: {
                y: { 
                  min: 0,
                  max: 100
                }
              }
            },
          },
        });
        //order taking chart
        const ctxOrderTaking = document.getElementById("ordertaking-chart").getContext("2d");
        this.orderTakingChart = new Chart(ctxOrderTaking, {
          type: "line",
          data: {
            labels: this.orderTakingListLabels,
            datasets: [
              {
                label: "平均接單處理時間",
                data: this.orderTakingListData,
                fill: false,
                borderColor: "#0ea5e9",
              },
            ],
          },
          plugins: [{}],
          options: {
            hover: { animationDuration: 0 },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            animation: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
              },
              scales: {
                y: { 
                  min: 0,
                  max: 100
                }
              }
            },
          },
        });
        //apply pay chart
        const ctxApplyPay = document.getElementById("applypay-chart").getContext("2d");
        this.applyPayChart = new Chart(ctxApplyPay, {
          type: "line",
          data: {
            labels: this.applyPayListLabels,
            datasets: [
              {
                label: "平均請款處理時間",
                data: this.applyPayListData,
                fill: false,
                borderColor: "#0ea5e9",
              },
            ],
          },
          plugins: [{}],
          options: {
            hover: { animationDuration: 0 },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            animation: {
              duration: 1,
            },
          },
        });
        //order log chart
        const ctxOrderLog = document.getElementById("orderlog-chart").getContext("2d");
        this.orderLogChart = new Chart(ctxOrderLog, {
          type: "line",
          data: {
            labels: this.orderLogListLabels,
            datasets: [
              {
                label: "接單自動化比例",
                data: this.orderLogListData,
                fill: false,
                borderColor: "#0ea5e9",
              },
            ],
          },
          plugins: [{}],
          options: {
            hover: { animationDuration: 0 },
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            animation: {
              tension: {
                duration: 1000,
                easing: 'linear',
                from: 1,
                to: 0,
                loop: false
              },
              scales: {
                y: { 
                  min: 0,
                  max: 100
                }
              }
            },
          },
        });
      })
    },
  },
};
</script>

<style lang="sass" type="text/sass" scoped>
.chart-area
    height: 500px
    position: relative
.chart-left-text
    position: absolute
    left: 5px
    top: 46%
</style>
